body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e1e1e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.text-left{
  text-align: left !;
}
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set a minimum height for the layout to take up the full viewport height */
  width: 100vw;
}

.top {
  flex: 0; /* Header and subheader will not scroll */
  position: sticky;
  top: 0 !important;
  z-index: 100; /* Set a z-index to make the header and subheader appear above the content */
}

.content {
  background-color: #eee;
  flex: 1 1;
  /* display: table; */
}


.colorName{
  color:"#3b5160"
}

.table_container_scroll {
  max-height: 80vh; 
  overflow-y: auto;  
}
.disabled{
  user-select: none;
  pointer-events: none;
}